import Image from "next/image";
import styled from "styled-components";

import planturaLogo from "~/assets/plantura-logo-full.svg";

const FullScreenPlanturaLogo = () => {
  return (
    <Wrapper>
      <Image src={planturaLogo} alt="Plantura logo" height="46" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export { FullScreenPlanturaLogo };
