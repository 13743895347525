import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
}

const ListItem = ({
  children,
  startIcon,
  endIcon,
  className,
  ...props
}: Props) => {
  return (
    <Wrapper className={className} {...props}>
      {startIcon}
      {children}
      {endIcon}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 10px 14px;
  font-size: 14px;
`;

ListItem.Root = Wrapper;

export { ListItem };
