import { useToastStore, Toast } from "~/stores/toast";

export const useToasts = () => {
  const { toasts, addToast: handleAddToast, setToasts } = useToastStore();

  const addToast = (toast: Toast) => {
    handleAddToast(toast);
  };

  return { toasts, addToast, setToasts };
};
