import { forwardRef } from "react";
import styled from "styled-components";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: string;
  type?: "text" | "password" | "email" | "number";
  defaultValue?: string | number;
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({ defaultValue, type = "text", ...props }, ref) => {
    return <Wrapper value={defaultValue} ref={ref} type={type} {...props} />;
  },
);

const Wrapper = styled.input<{
  readOnly?: boolean;
}>`
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px 12px;
  resize: none;
  background: transparent;
  cursor: ${(p) => p.readOnly && "default"};

  &:hover {
    border-color: transparent;
    outline: 1px solid #bab3b9;
  }

  &:focus {
    border-color: transparent;
    outline: 1px solid #645462;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: transparent;
    background: #ccc;
  }
`;

InputField.displayName = "InputField";
const InputFieldExtended = Object.assign({}, InputField, { Root: Wrapper });

export { InputFieldExtended as InputField };
