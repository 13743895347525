import { create } from "zustand";

type Toast = {
  id?: string | number;
  title: string;
  description: string;
};

type ToastStore = {
  toasts: Toast[];
  addToast: (toast: Toast) => void;
  setToasts: (toasts: Toast[]) => void;
};

const useToastStore = create<ToastStore>((set) => ({
  toasts: [],
  addToast: (toast) => set(() => ({ toasts: [toast] })),
  setToasts: (toasts) => set(() => ({ toasts })),
}));

export { useToastStore, type Toast };
