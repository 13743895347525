import styled from "styled-components";

interface Props {
  children?: string;
}

const Error = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  font-size: 12px;
  color: red;
`;

Error.Root = Wrapper;

export { Error };
