import styled, { css } from "styled-components";

type Space = "default" | "small" | "medium" | "large";

interface Props extends React.ComponentProps<"div"> {
  align?: "start" | "center" | "end";
  direction?: "row" | "column";
  gap?: number;
  justify?: string;
  space?: Space;
}

const Box = ({
  children,
  align,
  direction = "column",
  gap,
  space = "default",
  justify,
  className,
  style,
}: Props) => {
  return (
    <Wrapper
      align={align}
      direction={direction}
      gap={gap}
      space={space}
      justify={justify}
      className={className}
      style={style}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  align?: string;
  direction?: string;
  gap?: number;
  space?: Space;
  justify?: string;
}>`
  display: flex;
  flex-direction: ${(p) => p.direction};
  align-items: ${(p) => p.align};
  gap: ${(p) => `${p.gap}px`};
  justify-content: ${(p) => p.justify};
  ${(p) => p.space === "default" && SpaceDefault};
  ${(p) => p.space === "small" && SpaceSmall};
  ${(p) => p.space === "medium" && SpaceMedium};
  ${(p) => p.space === "large" && SpaceLarge};
`;

const SpaceDefault = css`
  gap: 0px;
`;

const SpaceSmall = css`
  gap: 8px;
`;

const SpaceMedium = css`
  gap: 24px;
`;

const SpaceLarge = css`
  gap: 32px;
`;

Box.Root = Wrapper;

export { Box };
