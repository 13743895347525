import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { createClient as createHttpClient } from "./http";

export class ApiError<Err> extends Error {
  constructor(
    public cause: unknown,
    public response: Err | undefined,
    public status: number,
    public statusText: string,
    public message: string,
  ) {
    super(message);
  }
}

export type ApiResponse<Res> = {
  data: Res | undefined;
  status: number;
  statusText: string;
};

const callApi = async <Res, Err>(
  http: AxiosInstance,
  requestOptions: AxiosRequestConfig,
): Promise<ApiResponse<Res>> => {
  try {
    const response = await http(requestOptions);
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (err) {
    // Handle AxiosError
    if (axios.isAxiosError(err)) {
      throw new ApiError<Err>(
        err,
        err.response?.data,
        err.response?.status || 0,
        err.response?.statusText || "Unspecified",
        err.message || "Unspecified error",
      );
    }
    // Handle any other error
    throw new ApiError<Err>(
      err,
      undefined,
      0,
      "Not available",
      err instanceof Error ? err.message : String(err),
    );
  }
};

export const createClient = () => {
  const http = createHttpClient();

  return {
    get: async <Res, Err = unknown>(
      url: string,
      config?: Partial<AxiosRequestConfig>,
    ) => {
      return callApi<Res, Err>(http, {
        ...config,
        url,
        method: "GET",
      });
    },
    post: async <Res, Err = unknown>(
      url: string,
      data: any,
      config?: Partial<AxiosRequestConfig>,
    ) => {
      return callApi<Res, Err>(http, {
        ...config,
        data,
        url,
        method: "POST",
      });
    },
    isApiError: <Err>(payload: unknown): payload is ApiError<Err> => {
      return payload instanceof ApiError;
    },
  };
};
