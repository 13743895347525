import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { Suspense, useEffect } from "react";
import "bootstrap/scss/bootstrap-grid.scss";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";

import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-listformat/polyfill";
import "@formatjs/intl-listformat/locale-data/de";
import "@formatjs/intl-listformat/locale-data/en";
import "@formatjs/intl-datetimeformat/polyfill";
import "@formatjs/intl-datetimeformat/locale-data/de";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/de";
import "@formatjs/intl-numberformat/locale-data/en";
import 'string.prototype.replaceall';

import { ErrorBoundary, NoSsr, Toasts } from "~/components";
import { usePlanturaStore } from "~/stores/plantura";
import { ToastProvider } from "~/ui/components";
import GlobalStyled from "~/ui/global.styled";
import HelpersStyled from "~/ui/helpers.styled";

import i18n from "../i18n";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { setClient } = usePlanturaStore();

  const { client } = router.query;

  useEffect(() => {
    if (!client) return;

    setClient(String(client));
  }, [client, router, router.isReady, setClient]);

  return (
    <NoSsr>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          name="viewport"
        />
      </Head>
      <ThemeProvider theme={{ mode: "light" }}>
        <I18nextProvider i18n={i18n}>
          <GlobalStyled />
          <HelpersStyled />
          <ToastProvider duration={5000}>
            <ErrorBoundary>
              <Suspense fallback={<span>Loading...</span>}>
                <Component {...pageProps} />
                <Toasts />
              </Suspense>
            </ErrorBoundary>
          </ToastProvider>
        </I18nextProvider>
      </ThemeProvider>
    </NoSsr>
  );
}
