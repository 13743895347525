import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import appConfig from "~/config/app";
import { Box, Button, Text } from "~/ui/components";
import { Loader } from "~/ui/components/loader";

const FullScreenLoader = () => {
  const [showReloadLink, setShowReloadLink] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setShowReloadLink(true);
    }, 20 * 1000); // 20 seconds
  }, []);

  return (
    <Wrapper>
      {showReloadLink && <Reload />}
      {!showReloadLink && <Loader />}
    </Wrapper>
  );
};

const Reload = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box space="large">
        <Text>{t("common.tryAgainSomethingWentWrong")}</Text>
        <Box align="center">
          <a href={appConfig.publicBaseUrl}>
            <Button variant="link">{t("common.reload")}</Button>
          </a>
        </Box>
      </Box>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export { FullScreenLoader };
