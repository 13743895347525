import { create } from "zustand";
import { persist } from "zustand/middleware";

type PlanturaStore = {
  client: string | null;
  getClient: () => string;
  setClient: (client: string) => void;
};

const usePlanturaStore = create(
  persist<PlanturaStore>(
    (set, get) => ({
      client: null,
      getClient: () => get().client || "self",
      setClient: (client) => set(() => ({ client })),
    }),
    {
      name: "plantura",
    },
  ),
);

export { usePlanturaStore };
