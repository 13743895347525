import styled from "styled-components";

type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const headingSizeMap: Record<HeadingElements, string> = {
  h1: "2.125rem",
  h2: "1.875rem",
  h3: "1.5rem",
  h4: "1.25rem",
  h5: "1.125rem",
  h6: "1rem",
};

interface Props extends React.ComponentProps<"h1"> {
  element?: HeadingElements;
}

const Heading = ({ element = "h1", children, className }: Props) => {
  return (
    <Wrapper as={element} className={className}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.h1<{
  as: HeadingElements;
}>`
  font-size: ${(p) => headingSizeMap[p.as]};
`;

Heading.Root = Wrapper;

export { Heading };
