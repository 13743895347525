import axios, { AxiosRequestConfig } from "axios";

import appConfig from "~/config/app";

export const urlForPath = (path: string) => {
  return new URL(path, appConfig.publicBaseUrl).toString();
};

export const pathWithQuery = (path: string, query: Record<string, any>) => {
  const searchParams = new URLSearchParams(path);

  Object.keys(query).forEach((param) => {
    searchParams.append(param, query[param]);
  });

  return decodeURIComponent(searchParams.toString());
};

export const createClient = (config?: Partial<AxiosRequestConfig>) => {
  const axiosInstance = axios.create({
    headers: {
      "Content-type": "application/json",
      ...config?.headers,
    },
  });

  return axiosInstance;
};
