import { createGlobalStyle } from "styled-components";

/**
 * Helpers class differ from global styling.
 * It should be only used in a specific cases when we want to slightly modify styles.
 * It's highly recommended to not include classes until we need them.
 */

export default createGlobalStyle`
  .text-center {
    text-align: center !important;
  }
`;
