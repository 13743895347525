import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import appConfig from "~/config/app";

const bugsnag = Bugsnag.createClient({
  apiKey: "0938c106699c88bc58054fc473343e4a",
  plugins: [new BugsnagPluginReact()],
  releaseStage: appConfig.publicAppEnv || "development",
  onError: () => appConfig.publicAppEnv !== "local",
});

export { bugsnag };
