import * as ToastPrimitive from "@radix-ui/react-toast";
import React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  title: string;
  description: string;
  open?: boolean;
  onOpenChange: () => void;
  onClose?: (payload: unknown) => void;
}

type ToastProviderProps = {
  children: React.ReactElement;
  duration?: number;
};

const ToastProvider = ({ children, duration = 4000 }: ToastProviderProps) => {
  return (
    <ToastPrimitive.Provider swipeDirection="right" duration={duration}>
      {children}
      <StyledViewPort />
    </ToastPrimitive.Provider>
  );
};

const Toast = ({ title, description, open, onOpenChange, onClose }: Props) => {
  return (
    <StyledRoot open={open} onOpenChange={onOpenChange}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      <StyledClose {...(onClose && { onClick: onClose })}>Close</StyledClose>
    </StyledRoot>
  );
};

const VIEWPORT_PADDING = 25;

const hide = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(calc(100% + ${VIEWPORT_PADDING}px));
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + ${VIEWPORT_PADDING}px));
  }
  to {
    transform: translateX(0);
  }
`;

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + ${VIEWPORT_PADDING}px));
  }
`;

const StyledViewPort = styled(ToastPrimitive.ToastViewport)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 10px;
  width: 368px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 1000;
  outline: none;
`;

const StyledRoot = styled(ToastPrimitive.Root)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #ccc;

  &[data-state="open"] {
    animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state="closed"] {
    animation: ${hide} 100ms ease-in forwards;
  }

  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe="end"] {
    animation: ${swipeOut} 100ms ease-out forwards;
  }
`;

const StyledTitle = styled(ToastPrimitive.Title)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #000;
`;

const StyledDescription = styled(ToastPrimitive.Description)`
  font-size: 13px;
  line-height: 110%;
  color: #000;
  margin-top: 8px;
`;

const StyledClose = styled(ToastPrimitive.Close)`
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  width: auto;
  height: 24px;
  border-radius: 4px;
  background: #f8f8f8;
  border: 0;

  &:hover {
    background-color: #ccc;
    color: #000;
  }
`;

export { Toast, ToastProvider };
