import { Configuration, FrontendApi } from "@ory/client";

import oryConfig from "~/config/ory";
import { createClient } from "~/libs/http";

export const createOryApi = () => {
  const axiosInstance = createClient({
    headers: { "Content-type": "application/json" },
  });

  return new FrontendApi(
    new Configuration({
      basePath: oryConfig.publicBaseUrl,
      baseOptions: { withCredentials: true },
    }),
    undefined,
    axiosInstance,
  );
};
