import { useCallback, useMemo, useState } from "react";

import { ApiResponse, createClient } from "~/libs/api";

const useApi = <Res, Err = unknown>() => {
  const api = useMemo(() => createClient(), []);

  const [inProgress, setInProgress] = useState(false);

  const callApi = useCallback(
    async (pendingResponse: Promise<ApiResponse<Res>>) => {
      try {
        setInProgress(true);
        return await pendingResponse;
      } catch (error) {
        throw error;
      } finally {
        setInProgress(false);
      }
    },
    [],
  );

  const get = useCallback(
    (url: string, config?: Parameters<typeof api.get>[1]) => {
      const pendingResponse = api.get<Res, Err>(url, config);
      return callApi(pendingResponse);
    },
    [api, callApi],
  );

  const post = useCallback(
    (url: string, data: any, config?: Parameters<typeof api.post>[2]) => {
      const pendingResponse = api.post<Res, Err>(url, data, config);
      return callApi(pendingResponse);
    },
    [api, callApi],
  );

  return {
    inProgress,
    get,
    post,
  };
};

export { useApi };
