import { v4 as UuidV4 } from "uuid";

import { useToasts } from "~/hooks";
import { Toast } from "~/ui/components/toast";

const Toasts = () => {
  const { toasts, setToasts } = useToasts();

  if (!toasts.length) return null;

  return (
    <>
      {toasts.map((toast) => {
        const id = toast.id || UuidV4();

        return (
          <Toast
            key={id}
            open
            onOpenChange={() =>
              setToasts(toasts.filter((t) => t.id !== toast.id))
            }
            title={toast.title}
            description={toast.description}
          />
        );
      })}
    </>
  );
};

export { Toasts };
