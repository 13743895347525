import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

import de from "~/lang/de.json";
import en from "~/lang/en.json";

const fallbackResources = {
  en,
  de,
};

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [resourcesToBackend(fallbackResources)],
    },
  });

i18n.services.formatter?.add("titlecase", (value: string) => {
  return value.replace(/\w\S*/g, function (value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  });
});

export default i18n;
