import styled, { css } from "styled-components";

import { Apple } from "~/ui/icons/apple";
import { CheckMark } from "~/ui/icons/checkmark";
import { Facebook } from "~/ui/icons/facebook";
import { Google } from "~/ui/icons/google";

type IconSize = "default" | "small" | "medium";
type IconName = (typeof icons)[number];
type Variant = "default" | "primary";

const icons = ["facebook", "google", "apple", "checkmark"] as const;

const variantColorMap: Record<Variant, string> = {
  default: "#000",
  primary: "#7daf6b",
};

interface Props {
  size?: IconSize;
  icon: IconName;
  variant?: Variant;
  className?: string;
}

const Icon = ({ size = "default", icon, variant, className }: Props) => {
  return (
    <Wrapper size={size} className={className}>
      {icon === "apple" && <Apple />}
      {icon === "facebook" && <Facebook />}
      {icon === "google" && <Google />}
      {icon === "checkmark" && (
        <CheckMark color={variantColorMap[variant ?? "default"]} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.span<{
  size: IconSize;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${(p) => p.size === "default" && SizeDefault}
  ${(p) => p.size === "small" && SizeSmall}
  ${(p) => p.size === "medium" && SizeMedium}
`;

const SizeSmall = css`
  width: 1em;
  height: 1em;
`;

const SizeDefault = css`
  width: 1.2em;
  height: 1.2em;
`;

const SizeMedium = css`
  width: 1.4em;
  height: 1.4em;
`;

Icon.Root = Wrapper;

export { Icon };
