import styled from "styled-components";

interface Props {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}

const List = ({ children, className, ...props }: Props) => {
  return (
    <Wrapper className={className} {...props}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

List.Root = Wrapper;

export { List };
