import Bugsnag from "@bugsnag/js";

import appConfig from "~/config/app";

const bugsnag = Bugsnag.createClient({
  apiKey: "9d432341e1f4570cb7cc31fe09272091",
  releaseStage: appConfig.publicAppEnv || "development",
  onError: () => appConfig.publicAppEnv !== "local",
});

export { bugsnag };
