import { palette } from "@plantura-garden/palette";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    :root {
      --base-font-size: 16px;
      --base-font-family: 'Golos Text', sans-serif;
      --background: ${palette.white};
      --background-secondary: #f8f8f8;
      --background-dark: ${palette.black};
      --background-secondary-dark: #282828;
      --danger: ${palette.danger500};
      --primary-lighter: ${palette.green50};
      --primary-light: ${palette.green200};
      --primary: ${palette.green500};
      --primary-dark: ${palette.green700};
      --secondary-lighter: ${palette.beige50};
      --secondary-light: ${palette.beige500};
      --secondary: ${palette.beige100};
      --secondary-dark: ${palette.beige600};
      --purple-light: ${palette.purple300};
      --purple: ${palette.purple500};
      --purple-dark: ${palette.purple700};
      --gray-dark: ${palette.gray200};
    }

  * {
    margin: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
  }

  body {
    height: 100%;
    background: var(--background-secondary);
    font-size: var(--base-font-size);
    font-family: var(--base-font-family);
  }

  a {
    text-decoration: none;
  }
`;
