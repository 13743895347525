import { forwardRef } from "react";
import styled, { css } from "styled-components";

type Variant = "primary" | "secondary" | "light" | "link";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | React.ReactNode;
  element?: "button" | "a";
  variant?: Variant;
  type?: "button" | "submit";
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  ref?: React.RefObject<HTMLButtonElement>;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    { variant = "primary", element, startIcon, endIcon, children, ...props },
    ref,
  ) => {
    return (
      <Wrapper as={element} ref={ref} variant={variant} {...props}>
        {startIcon}
        {children}
        {endIcon}
      </Wrapper>
    );
  },
);

/**
 * Styles
 */

const Wrapper = styled.button<{
  disabled?: boolean;
  fullWidth?: boolean;
  variant: Variant;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 14px;
  font-size: 14px;
  width: ${(p) => p.fullWidth && "100%"};
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  ${(p) => p.variant === "primary" && Primary}
  ${(p) => p.variant === "secondary" && Secondary}
  ${(p) => p.variant === "light" && Light}
  ${(p) => p.variant === "link" && Link}
`;

const Primary = css`
  background: #7daf6b;
  border-color: #7daf6b;
  color: #fff;

  &:hover {
    background: #709d60;
  }

  &[disabled] {
    background: #cccccc;
    color: #fff;
  }
`;

const Secondary = css`
  background: #efeee5;
  border-color: #efeee5;
  color: #000;

  &:hover {
    background: #e7e6d8;
  }

  &[disabled] {
    background: #cccccc;
    color: #fff;
  }
`;

const Light = css`
  background: #fff;
  border-color: #dae0e5;
  color: #212529;

  &:hover {
    background-color: #e2e6ea;
    color: #212529;
  }

  &[disabled] {
    background: #cccccc;
    color: #fff;
  }
`;

const Link = css`
  background: #fff;
  border-color: #fff;
  color: #7daf6b;

  &:hover {
    color: #4b6940;
    text-decoration: underline;
  }

  &[disabled] {
    background: #cccccc;
    color: #fff;
  }
`;

/**
 * Issue: React forwardRef with custom static props
 * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-1008349828
 */

Button.displayName = "Button";
const ButtonExtended = Object.assign({}, Button, { Root: Wrapper });

export { ButtonExtended as Button };
