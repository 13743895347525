import React from "react";
import styled, { css } from "styled-components";

type Variant = "primary" | "secondary" | "light";

interface Props {
  children: React.ReactNode;
  className?: string;
  variant?: Variant;
  style?: object;
}

const Card = ({
  children,
  variant = "primary",
  style,
  className,
  ...props
}: Props) => {
  return (
    <Wrapper variant={variant} style={style} className={className} {...props}>
      {children}
    </Wrapper>
  );
};

/**
 * Styles
 */

const Wrapper = styled.div<{
  variant: Variant;
}>`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  ${(p) => p.variant === "primary" && Primary}
  ${(p) => p.variant === "secondary" && Secondary}
  ${(p) => p.variant === "light" && Light}
`;

const Primary = css``;

const Secondary = css``;

const Light = css`
  background-color: #fff;
`;

Card.Root = Wrapper;

export { Card };
