import styled, { css } from "styled-components";

type Size = "default" | "small";
type Variant = "default" | "primary" | "secondary";

interface Props {
  children?: React.ReactNode;
  element?: "p" | "span" | "label";
  variant?: Variant;
  size?: Size;
  style?: object;
  className?: string;
}

const Text = ({
  children,
  element = "p",
  variant = "default",
  size = "default",
  style,
  className,
}: Props) => {
  return (
    <Wrapper
      as={element}
      style={style}
      className={className}
      variant={variant}
      size={size}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.p<{
  variant: Variant;
  size: Size;
}>`
  ${(p) => p.variant === "default" && Default};
  ${(p) => p.variant === "primary" && Primary};
  ${(p) => p.variant === "secondary" && Secondary};
  ${(p) => p.size === "default" && SizeDefault};
  ${(p) => p.size === "small" && SizeSmall};
`;

const Default = css`
  color: #000;
`;

const Primary = css`
  color: #7daf6b;
`;

const Secondary = css`
  color: #ccc;
`;

const SizeDefault = css`
  font-size: 14px;
`;

const SizeSmall = css`
  font-size: 13px;
`;

Text.Root = Wrapper;

export { Text };
