import styled from "styled-components";

interface Props {
  children: React.ReactElement;
  name?: string;
  value?: string;
  className?: string;
}

const IconButton = ({ children, className, ...props }: Props) => {
  return (
    <Wrapper className={className} {...props}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #dae0e5;
  background: #fff;
  border-radius: 50%;
  width: min-content;
  cursor: pointer;

  &:hover {
    background: #efeee5;
  }
`;

IconButton.Root = Wrapper;

export { IconButton };
