import styled from "styled-components";

interface Props {
  children: string;
  className?: string;
}

const Seperator = ({ children, className }: Props) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:after,
  &:before {
    content: " ";
    height: 1px;
    background: #ccc;
    flex-grow: 1;
  }

  &:after {
    margin-left: 8px;
  }

  &:before {
    margin-right: 8px;
  }
`;

Seperator.Root = Wrapper;

export { Seperator };
